import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivityLogService, TemplateActivityService } from "@app-cfs/shared/services";
import { interval, of, Subject } from "rxjs";
import { catchError, switchMap, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.css"]
})
export class CarouselComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private activityLogService: ActivityLogService, private templateActivityService: TemplateActivityService) {}

  ngOnInit(): void {
    interval(TemplateActivityService.syncTimeout)
      .pipe(
        switchMap(() => {
          const list = this.templateActivityService.getEventList();
          if (!list.length) {
            return of({ skipUpdate: true });
          }

          return this.activityLogService.logTemplatesActivity(list).pipe(catchError(() => of({ skipUpdate: true })));
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result) => {
        if (!result?.skipUpdate) {
          this.templateActivityService.clearEventList();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
