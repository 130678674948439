import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { AccountConfigurationDto, CarouselUserActionType } from "@app-cfs/shared/models";
import { CarouselService, CurrentAccountService } from "@app-cfs/shared/services";
import * as Hammer from "hammerjs";
import { map, take } from "rxjs/operators";

@Component({
  selector: "app-secret-gesture",
  templateUrl: "./secret-gesture.component.html",
  styleUrls: ["./secret-gesture.component.scss"]
})
export class SecretGestureComponent implements OnInit, AfterViewInit {
  @ViewChild("gesture", { static: true }) gestureElement: ElementRef | null = null;

  private destinationUrl = "";
  private isPincodeAvailable = false;

  constructor(private accountService: CurrentAccountService, private carouselService: CarouselService) {}

  ngOnInit(): void {
    this.accountService
      .getCurrentAccount()
      .pipe(
        take(1),
        map((account) => account.configuration)
      )
      .subscribe((configuration: AccountConfigurationDto) => {
        this.destinationUrl = configuration.urlsConfiguration?.secretGestureDestinationUrl || "";
        this.isPincodeAvailable = !!configuration.security?.isPinCodeEnabled;
      });
  }

  ngAfterViewInit(): void {
    const hammerManager = new Hammer.Manager(this.gestureElement?.nativeElement, {});
    const secretTap = new Hammer.Tap({
      event: "secretTap",
      taps: 5,
      interval: 2000,
      posThreshold: 100
    });

    hammerManager.add([secretTap]);
    hammerManager.on("secretTap", this.onClick.bind(this));
  }

  onClick(): void {
    if (this.destinationUrl) {
      this.carouselService.sendUserAction({
        actionType: this.isPincodeAvailable ? CarouselUserActionType.pincode : CarouselUserActionType.gestureDestination,
        redirectUrl: this.destinationUrl,
        confirmActivity: true,
        trackActivity: true
      });
    }
  }
}
