export enum BrandType {
  Undefined = 0,
  Private = 1,
  Public = 2
}
export interface Brand {
  id: number;
  name: string;
  shortName: string;
  brandHeroImg: string;
  logo: string;
  website: string;
  facebook: string;
  instagram: string;
  twitter: string;
  reddit: string;
  snapchat: string;
  slogan: string;
  disclaimer: string;
  termsAndConditionsUrl: string;
  privacyPolicyUrl: string;
  country: number;
  brandType: BrandType;
  ownerId: number;
  notificationId: string;
  industryId: number;
}
