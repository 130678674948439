import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CarouselService } from "@app-cfs/shared/services";
import { CarouselUserActionType, CfsMenuItemModel } from "@app-cfs/shared/models";

@Component({
  selector: "app-menu-button",
  templateUrl: "./menu-button.component.html",
  styleUrls: ["./menu-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuButtonComponent {
  @Input() menuList: CfsMenuItemModel[] = [];

  constructor(private carouselService: CarouselService) {}

  onClick(): void {
    this.carouselService.sendUserAction({
      actionType: CarouselUserActionType.menu
    });
  }
}
