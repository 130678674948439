import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of, timer } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { AuthRequest, AuthResponse, CoreStorageKeys } from "../models";
import { AccountService } from "./account.service";
import { LocalStorageService } from "./local-storage.service";
import { AppConfigService } from "core-kit";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  refreshSubscription: any;
  expiryRefreshTime = 30 * 1000; // Refresh 30 seconds before expiry
  requestedScopes: string = "clearline_api";

  constructor(
    public httpClient: HttpClient,
    private confService: AppConfigService,
    private accountService: AccountService,
    private storageSvc: LocalStorageService
  ) {}

  public login() {
    return this.authorize();
  }
  public tryLogin() {
    const cashRegisterId = this.storageSvc.getItem(CoreStorageKeys.cashRegister);
    if (!cashRegisterId) return of();
    return this.authorize();
  }

  public authorize() {
    const cashRegisterId = this.storageSvc.getItem(CoreStorageKeys.cashRegister);
    var kioskClientId = this.confService.appData.kioskClientId;
    if (this.confService.appData.domainId) {
      kioskClientId = kioskClientId;
    }
    const authBody: AuthRequest = {
      grant_type: "client_credentials",
      scope: this.requestedScopes,
      client_id: kioskClientId,
      client_secret: this.confService.appData.kioskClientSecret,
      acr_values: "cashregister:" + cashRegisterId
    };

    let options = {
      headers: new HttpHeaders().set("Content-Type", "application/x-www-form-urlencoded")
    };
    let body = new URLSearchParams();
    for (const key in authBody) {
      if (Object.prototype.hasOwnProperty.call(authBody, key)) {
        const val = (authBody as any)[key];
        body.set(key, val);
      }
    }
    return this.httpClient
      .post<AuthResponse>(this.confService.appData.identityUrl + "/connect/token", body.toString(), options)
      .pipe(map((r) => this.setSession(r)));
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    this.storageSvc.clearItem(CoreStorageKeys.accessToken);
    this.storageSvc.clearItem(CoreStorageKeys.expiresAt);
    this.unscheduleRenewal();
  }
  public isValidToken(): boolean {
    // Check whether the current time is past the
    // access token's expiry time

    const expiresAt = JSON.parse(this.storageSvc.getItem(CoreStorageKeys.expiresAt));
    return new Date().getTime() < expiresAt - this.expiryRefreshTime;
  }

  public scheduleRenewal() {
    const expiresAt = JSON.parse(this.storageSvc.getItem(CoreStorageKeys.expiresAt));

    this.refreshSubscription = of(expiresAt)
      .pipe(
        mergeMap((expiresAt) => {
          const now = Date.now();

          var refreshAt = expiresAt - this.expiryRefreshTime;
          return timer(Math.max(1, refreshAt - now));
        }),
        mergeMap((_) => this.authorize())
      )
      .subscribe();
  }

  public unscheduleRenewal() {
    if (!this.refreshSubscription) return;
    this.refreshSubscription.unsubscribe();
  }
  private setSession(authResult: AuthResponse) {
    // Set the time that the access token will expire at
    const expiresAtTime = JSON.stringify(authResult.expires_in * 1000 + new Date().getTime());

    this.storageSvc.setItem(CoreStorageKeys.accessToken, authResult.access_token);
    this.storageSvc.setItem(CoreStorageKeys.expiresAt, expiresAtTime);
    this.storageSvc.setItem(CoreStorageKeys.expiresIn, authResult.expires_in.toString());
    this.scheduleRenewal();

    this.accountService.setAccessToken(authResult.access_token);
  }
}
