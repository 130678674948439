import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedComponentsModule } from "@app-cfs/shared/components";
import { CfsTemplateComponent } from "clearline-common";
import player from "lottie-web";
import { provideLottieOptions } from "ngx-lottie";
import { CarouselComponent } from "./carousel.component";
import { CFSCarouselComponent } from "./cfs-carousel/cfs-carousel.component";
import { SecretGestureComponent } from "./secret-gesture/secret-gesture.component";
import { MenuButtonComponent } from "./menu-button";

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [CommonModule, CfsTemplateComponent, SharedComponentsModule],
  declarations: [
    CarouselComponent,
    CFSCarouselComponent,
    SecretGestureComponent,
    MenuButtonComponent
  ],
  providers: [
    provideLottieOptions({
      player: () => player
    })
  ],
  exports: [CarouselComponent]
})
export class CarouselModule {}
