import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { CfsCommunicationPackModule, CFSMessagingService } from "cfs-communication-pack";
import { CLEARLINE_API_CONFIG_TOKEN, ClearlineApiModule } from "clearline-api";
import { AppConfigService, CoreKitConfig, CoreKitModule } from "core-kit";
import { CarouselModule as BootstrapCarouselModule } from "ngx-bootstrap/carousel";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthInterceptor } from "./core/interceptors";
import { AccountService, GlobalErrorHandler } from "./core/services";
import { TranslocoRootModule } from "./core/transloco-root.module";
import { CarouselModule } from "./modules";
import { SharedComponentsModule } from "./shared/components";

export function appConfigServiceFactory(appService: AppConfigService): Function {
  return () => appService.getAppData();
}

const coreKitConfig: CoreKitConfig = {
  baseName: environment.baseName,
  apiName: environment.apiName,
  isProduction: environment.production,
  redirectUrlKey: "cfsUrl"
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    BootstrapCarouselModule.forRoot(),
    NoopAnimationsModule,
    HttpClientModule,
    HammerModule,
    SharedComponentsModule,
    CarouselModule,
    TranslocoRootModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000"
    }),
    CfsCommunicationPackModule.forRoot(environment.pubNubConfig),
    CoreKitModule.forRoot(coreKitConfig),
    ClearlineApiModule
  ],
  providers: [
    CFSMessagingService,
    AppConfigService,
    AccountService,
    { provide: APP_INITIALIZER, useFactory: appConfigServiceFactory, deps: [AppConfigService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: CLEARLINE_API_CONFIG_TOKEN,
      useFactory: (appConfigService: AppConfigService) => ({
        apiUrl: appConfigService.apiUrl
      }),
      deps: [AppConfigService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
