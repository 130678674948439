export interface Account {
  id?: number;
  companyName: string;
  billingCountry?: string;
  billingCity: string;
  billingWebsite: string;
  billingState: string;
  billingAddress: string;
  billingContactEmail: string;
  billingContactLastName: string;
  billingContactFirstName: string;
  billingEmail: string;
  billingPhoneNumber: string;
  billingZip: number;
  billingLongState: string;
  isBillingAddress: boolean;
  salesAgentId?: number;
  companyFullName?: string;
  numberOfLocations?: number;
  isPosIntegrationAvailable?: boolean;
  posSystemId?: number;
  companyPosId?: string;
  configuration: AccountConfigurationDto;
}

export interface AccountUrlsConfigurationTagDto {
  title?: string;
  urls?: string;
  useIn?: string;
}

export interface SecurityConfigurationDto {
  isPinCodeEnabled?: boolean;
}

export interface UrlsConfigurationDto {
  secretGestureDestinationUrl?: string;
  tags?: AccountUrlsConfigurationTagDto;
}

export interface AccountConfigurationDto {
  templatesConfiguration: AccountTemplateConfiguration;
  urlsConfiguration: UrlsConfigurationDto;
  security?: SecurityConfigurationDto;
}

export interface AccountTemplateConfiguration {
  isReadOnly: boolean;
  isAllEditableTemplatesAllowed: boolean;
  allowedEditableTemplates: number[];
}
