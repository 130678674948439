import { Injectable } from "@angular/core";
import { combineLatest, forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { ContentSettings, ScreenSizesJsonAsset, Template, TemplateBasePreviewOptions } from "clearline-common";
import {
  DigitalAssetGetDto,
  DigitalAssetService,
  KeyValueAsset,
  MediaContentDto,
  MediaContentService,
  DigitalAssetScreenSize,
  TemplateService
} from "clearline-api";

interface BasePreviewOptionsAsset {
  [key: string]: TemplateBasePreviewOptions;
}

interface TemplateAssetIdLists {
  mediaContentIds: number[];
  assetIds: number[];
}

@Injectable({
  providedIn: "root"
})
export class TemplatesService {
  constructor(
    private templateService: TemplateService,
    private digitalAssetService: DigitalAssetService,
    private mediaContentService: MediaContentService
  ) {}

  getPreparedTemplates$(templateList: Template[]): Observable<Template[]> {
    // preloading content preview options (for lottie templates):
    const lottieTemplates: Template[] = templateList.filter((item: Template) => TemplatesService.getTemplateFilterCondition(item));
    const { mediaContentIds, assetIds } = this.getUniqueContentIdLists(templateList);

    if (mediaContentIds?.length || assetIds?.length) {
      const mediaOptions$ = mediaContentIds?.length
        ? combineLatest(mediaContentIds.map((item: number) => this.getMediaPreviewOptions$(item)))
        : of([]);
      const assetOptions$ = assetIds?.length
        ? combineLatest(assetIds.map((item: number) => this.getTemplateBasePreviewOptions$(item)))
        : of([]);

      return combineLatest([assetOptions$, mediaOptions$]).pipe(
        map(([assetOptions, mediaOptions]) => {
          return this.getHandledTemplatesResult(
            templateList,
            lottieTemplates,
            mediaOptions as TemplateBasePreviewOptions[],
            assetOptions as TemplateBasePreviewOptions[]
          );
        })
      );
    }

    return of(templateList);
  }

  private getUniqueContentIdLists(lottieTemplates: Template[]): TemplateAssetIdLists {
    const mediaContentIds: number[] = [];
    const assetIds: number[] = [];

    lottieTemplates.forEach((item: Template) => {
      const mediaContentId: number | undefined = TemplatesService.getMediaContentId(item);

      if (mediaContentId) {
        if (!mediaContentIds.includes(mediaContentId)) mediaContentIds.push(mediaContentId);
      } else {
        const assetId: number | undefined = TemplatesService.getAssetId(item);

        if (assetId && !assetIds.includes(assetId)) assetIds.push(assetId);
      }
    });

    return { mediaContentIds, assetIds };
  }

  private getMediaPreviewOptions$(mediaContentId: number): Observable<TemplateBasePreviewOptions | null> {
    return this.mediaContentService.getMediaContent(mediaContentId).pipe(
      catchError(() => of(null as unknown as MediaContentDto)),
      mergeMap((mediaContent: MediaContentDto) => {
        if (!mediaContent) return of(null);

        const digitalAssets: DigitalAssetGetDto[] = mediaContent?.digitalAssets || [];
        const contentUrlAsset: ScreenSizesJsonAsset = digitalAssets.reduce((acc: ScreenSizesJsonAsset, item: DigitalAssetGetDto) => {
          if (item.configuration) {
            const screenSize: DigitalAssetScreenSize = item.configuration.screenSize;

            acc[screenSize] = item.contentUrl;
          }

          return acc;
        }, {} as ScreenSizesJsonAsset);

        return combineLatest([of(mediaContent), this.getScreenSizesJsonAsset$(contentUrlAsset)]).pipe(
          map(([resultMediaContent, screenSizesJsonAsset]: [MediaContentDto, ScreenSizesJsonAsset]) => {
            return resultMediaContent ? { mediaContent: resultMediaContent, screenSizesJsonAsset } : null;
          })
        );
      })
    );
  }

  public getScreenSizesJsonAsset$(contentUrlAsset: ScreenSizesJsonAsset): Observable<ScreenSizesJsonAsset> {
    const jsonList$: Observable<ScreenSizesJsonAsset | null>[] = Object.keys(contentUrlAsset).map((key: string) => {
      const contentUrl: string = contentUrlAsset[key as DigitalAssetScreenSize] as string;

      return this.getContentByUrlWrapper$(contentUrl, key as DigitalAssetScreenSize);
    });

    return forkJoin(jsonList$).pipe(
      map((assetList) => {
        return assetList.reduce((acc, item: ScreenSizesJsonAsset | null) => {
          if (item) {
            acc = { ...acc, ...item };
          }
          return acc;
        }, {} as ScreenSizesJsonAsset) as ScreenSizesJsonAsset;
      })
    );
  }

  private getTemplateBasePreviewOptions$(assetId: number): Observable<TemplateBasePreviewOptions | null> {
    return this.digitalAssetService.getAsset(assetId).pipe(
      catchError(() => of(null as unknown as DigitalAssetGetDto)),
      mergeMap((asset: DigitalAssetGetDto) => {
        const contentUrl = (asset as DigitalAssetGetDto)?.contentUrl;

        return combineLatest([of(asset), this.getContentByUrl$(contentUrl)]);
      }),
      map(([digitalAsset, json]: [DigitalAssetGetDto, string]) => {
        return digitalAsset ? { digitalAsset, json } : null;
      })
    );
  }

  private getContentByUrl$(contentUrl: string = ""): Observable<string> {
    if (contentUrl) {
      return this.templateService.getContentByUrl(contentUrl as string).pipe(catchError(() => of("")));
    }

    return of("");
  }

  private getContentByUrlWrapper$(contentUrl: string, screenSize: DigitalAssetScreenSize): Observable<ScreenSizesJsonAsset | null> {
    return this.getContentByUrl$(contentUrl).pipe(map((json: string) => (json ? { [screenSize]: json } : null)));
  }

  private getHandledTemplatesResult(
    templateList: Template[],
    lottieTemplates: Template[],
    mediaOptions: TemplateBasePreviewOptions[] = [],
    options: TemplateBasePreviewOptions[] = []
  ): Template[] {
    const mediaAssets: BasePreviewOptionsAsset = TemplatesService.getBasePreviewOptionsAsset(mediaOptions);
    const assets: BasePreviewOptionsAsset = TemplatesService.getBasePreviewOptionsAsset(options);

    if (TemplatesService.isObjectEmpty(mediaAssets) && TemplatesService.isObjectEmpty(assets)) {
      return templateList.filter((item: Template) => !lottieTemplates.some((lottieItem: Template) => item.id === lottieItem.id));
    }

    lottieTemplates.forEach((item: Template) => {
      const itemMediaId: number | undefined = item.configuration?.mediaContentId; // for media
      const contentSettings: ContentSettings = item.templateProperties as ContentSettings; // for not media type
      const itemAssetId: number | undefined = contentSettings?.assetId; // for not media type

      if (itemMediaId && mediaAssets[itemMediaId]) {
        const defaultParameters: KeyValueAsset | undefined = item.configuration?.defaultParameters;
        item.previewOptions = { ...mediaAssets[itemMediaId], defaultParameters, contentSettings };
      } else if (itemAssetId && assets[itemAssetId]) {
        const itemOptions: TemplateBasePreviewOptions = assets[itemAssetId];
        item.previewOptions = { ...itemOptions, contentSettings };
      }
    });

    return templateList;
  }

  private static getBasePreviewOptionsAsset(list: TemplateBasePreviewOptions[]): BasePreviewOptionsAsset {
    return list?.reduce((acc: BasePreviewOptionsAsset, item: TemplateBasePreviewOptions) => {
      const id: number = this.getBaseContentIdByOptions(item);

      if (id >= 0 && !acc.hasOwnProperty(id)) {
        acc[id] = item;
      }

      return acc;
    }, {} as BasePreviewOptionsAsset);
  }

  private static getBaseContentIdByOptions(options: TemplateBasePreviewOptions): number {
    const { mediaContent, digitalAsset } = options;

    if (mediaContent?.digitalAssets?.length) {
      return mediaContent.digitalAssets[0].mediaContentId as number;
    }

    return digitalAsset?.id as number;
  }

  private static isObjectEmpty(object: Object) {
    return Object.keys(object).length === 0;
  }

  private static getTemplateFilterCondition(template: Template): boolean {
    const assetId: number | undefined = TemplatesService.getAssetId(template);
    const mediaContentId: number | undefined = TemplatesService.getMediaContentId(template);

    return !!assetId || !!mediaContentId;
  }

  private static getMediaContentId(template: Template): number | undefined {
    return template.configuration?.mediaContentId;
  }

  private static getAssetId(template: Template): number | undefined {
    return (template.templateProperties as ContentSettings)?.assetId;
  }
}
