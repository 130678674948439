<div class="carousel-wrapper" *ngIf="ready$ | async">
  <app-menu-button *ngIf="menuList$ | async as menuList" [menuList]="menuList"></app-menu-button>
  <app-secret-gesture></app-secret-gesture>

  <div class="carousel">
    <div class="carousel-inner" [class.test-mode-carousel]="isTestMode$ | async">
      <div *ngFor="let template of templates$ | async; index as i" class="item" [class.active]="i === nextSlideIndex">
        <lib-cfs-template
          [isTestMode]="!!(isTestMode$ | async)"
          [index]="i"
          [mediaConfig]="{ muted: true, autoplay: false }"
          [template]="template"
          [isLowResolutionScreen]="isLowResolutionScreen"
          (templateClick)="onTemplateClick(template)"
          #templateComponent
          (ready)="templateReady(i === nextSlideIndex, i, templateComponent)"
        ></lib-cfs-template>
      </div>
    </div>
  </div>
</div>
