export interface CfsMenuDto {
  items?: CfsMenuItemModel[];
  brandName?: string;
}

export class CfsMenuItemModel {
  title!: string;
  icon!: string;
  pageUrl!: string;
}
