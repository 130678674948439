<button
  class="position-absolute btn end-0"
  [class.primary-btn]="buttonViewType === ButtonViewType.Primary"
  [class.text-btn]="buttonViewType === ButtonViewType.Primary"
  [class.icon-btn]="buttonViewType === ButtonViewType.Cross"
  (click)="goBack()"
  [ngSwitch]="buttonViewType">
  <img
    *ngSwitchCase="ButtonViewType.Cross"
    alt=""
    src="assets/images/cross.svg"
    class="icon-btn__img">

  <ng-container *ngSwitchCase="ButtonViewType.Primary">
    {{ "templateActionPage.backBtn" | transloco }}
  </ng-container>
</button>
