import { Injectable } from "@angular/core";

import { LocalStorageService } from "../../core/services";
import { CFSConnectionSettings } from "cfs-communication-pack";
import { StorageKeys } from "../models/storage-keys";
import { CacheService } from "@app-cfs/shared/services/cache.service";
import { Template } from "clearline-common";
import { DefaultTemplateType } from "clearline-api";

@Injectable({
  providedIn: "root"
})
export class SettingsHandlerService {
  private explicitCashRegisterId = 0;
  private explicitLocationId = 0;

  constructor(private localStorageService: LocalStorageService, private cacheService: CacheService) {}

  get templateList(): Template[] {
    return this.getTemplateListFromStorage();
  }

  get locationId(): number {
    return this.explicitLocationId || this.getLocationIdFromStorage();
  }

  get cashRegister(): number {
    return this.explicitCashRegisterId || this.getCashRegisterFromStorage();
  }

  startReset(): void {
    const code = this.localStorageService.getItem(StorageKeys.connectionCode);

    this.cacheService.clear();
    this.setResetInProgress();

    if (code) {
      this.localStorageService.setItem(StorageKeys.connectionCode, code);
    }
  }

  finishReset(): void {
    this.localStorageService.clearItem(StorageKeys.isResetInProgress);
  }

  setExplicitCashRegisterId(id: number): void {
    this.explicitCashRegisterId = id;
  }

  setExplicitLocationId(id: number): void {
    this.explicitLocationId = id;
  }

  saveTemplates(templates: Template[]): void {
    const filteredTemplates: Template[] = templates.filter((template: Template) => {
      return template.templateType === DefaultTemplateType.CfsAndPrint || template.templateType === DefaultTemplateType.Cfs;
    });

    this.setTemplateListToStorage(filteredTemplates);
  }

  saveSettings(settings: CFSConnectionSettings): void {
    const { locationId, cashRegisterId } = settings;

    this.saveCashRegister(cashRegisterId as number);
    this.saveLocation(locationId as number);
  }

  saveCashRegister(cashRegisterId: number): void {
    this.setCashRegisterToStorage(cashRegisterId);
  }

  saveLocation(locationId: number): void {
    this.setLocationToStorage(locationId);
  }

  setTemplateListToStorage(list: Template[]): void {
    this.localStorageService.setItem(StorageKeys.templateUrlListStorageKey, JSON.stringify(list));
  }

  isResetInProgress(): boolean {
    return this.localStorageService.getItem(StorageKeys.isResetInProgress) === "true";
  }

  clearAllStorage(): void {
    this.localStorageService.clearAll();
  }

  private getTemplateListFromStorage(): Template[] {
    return JSON.parse(this.localStorageService.getItem(StorageKeys.templateUrlListStorageKey));
  }

  private getLocationIdFromStorage(): number {
    const locationId = this.localStorageService.getItem(StorageKeys.locationStorageKey);

    return +locationId;
  }

  private getCashRegisterFromStorage(): number {
    return +this.localStorageService.getItem(StorageKeys.cashRegister);
  }

  private setCashRegisterToStorage(cashRegister: number): void {
    this.localStorageService.setItem(StorageKeys.cashRegister, cashRegister.toString());
  }

  private setLocationToStorage(location: number): void {
    this.localStorageService.setItem(StorageKeys.locationStorageKey, location.toString());
  }

  private setResetInProgress(): void {
    this.localStorageService.setItem(StorageKeys.isResetInProgress, "true");
  }
}
