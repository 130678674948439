import { ChangeDetectionStrategy, Component, EventEmitter, Input } from "@angular/core";
import { ButtonViewType } from "@app-cfs/shared/models";

@Component({
  selector: "app-action-back-button",
  templateUrl: "./action-back-button.component.html",
  styleUrls: ["./action-back-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionBackButtonComponent {
  readonly ButtonViewType = ButtonViewType;

  @Input() buttonViewType: ButtonViewType = ButtonViewType.Primary;

  click = new EventEmitter();

  goBack(): void {
    this.click.emit();
  }
}
