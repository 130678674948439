import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChildren } from "@angular/core";
import { SpinnerService } from "@app-cfs/shared/components/spinner";
import {
  CarouselUserActionType,
  TemplateActivity,
  TemplateActivityDataRedirectModel,
  TemplateActivityType,
  TemplatesCarouselData
} from "@app-cfs/shared/models";
import {
  ActivityLogService,
  CarouselService,
  LocalStorageService,
  ScreenSizeService,
  SettingsHandlerService,
  TemplateActivityService
} from "@app-cfs/shared/services";
import { AppConfigService } from "core-kit";
import { BehaviorSubject, combineLatest, interval, Observable, Subject } from "rxjs";
import { filter, map, mergeMap, switchMap, takeUntil, tap } from "rxjs/operators";
import { StorageKeys } from "@app-cfs/shared/models/storage-keys";
import { CfsTemplateComponent, Template } from "clearline-common";
import {
  GlobalTemplateType,
  TemplateConfigurationTriggerActionType,
  TemplateConfigurationTriggerAreaType,
  TemplateConfigurationTriggerType
} from "clearline-api";

@Component({
  selector: "app-cfs-carousel",
  templateUrl: "./cfs-carousel.component.html",
  styleUrls: ["./cfs-carousel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CFSCarouselComponent implements OnInit, OnDestroy {
  @ViewChildren(CfsTemplateComponent) templateComponents: CfsTemplateComponent[] = [];

  private readonly defaultInterval = 7000;
  private readonly delayBeforeUpdate = 30;

  private unsubscribe$ = new Subject<void>();
  private nextCircleUpdateData: TemplatesCarouselData | null = null;
  private forceDisplay = false;
  private displayTimeTimerId?: number;
  private isFirstTemplateReady = false;
  private nextSlideInterval$ = new BehaviorSubject<number>(0);

  nextSlideIndex = 0;
  isCarouselEmpty = false;
  isProduction = this.configService.isProduction;
  isLowResolutionScreen: boolean = this.screenSizeService.isLowResolutionScreen();

  readonly templates$ = new BehaviorSubject<Template[]>([]);
  readonly isTestMode$: Observable<boolean> = this.carouselService.isTestMode$;
  readonly menuList$ = this.carouselService.menuInfo$.pipe(map((info) => info?.items || []));

  private slideTimer$ = combineLatest([
    this.carouselService.isCarouselPlaying$.pipe(tap(() => this.clearDisplayTimeTimer())),
    this.nextSlideInterval$.pipe(switchMap((timeout) => interval(timeout)))
  ]).pipe(
    tap(([inProgress]) => {
      const ready: boolean = inProgress && !this.getIsCarouselEmpty(this.templates$.value);

      if (ready) {
        this.logTemplateShowActivity();
        this.prepareNextSlide(this.templates$.value, true);
      }
    })
  );

  ready$ = combineLatest([this.templates$, this.slideTimer$]).pipe(
    map(([list]) => !!list.length || this.isCarouselEmpty),
    tap((ready) => {
      if (ready) {
        this.spinnerService.hide();
      } else {
        this.spinnerService.show();
      }
    }),
    filter((ready) => ready)
  );

  constructor(
    private carouselService: CarouselService,
    private templateActivityService: TemplateActivityService,
    private configService: AppConfigService,
    private spinnerService: SpinnerService,
    private localStorageService: LocalStorageService,
    private activityLogService: ActivityLogService,
    private settingsHandler: SettingsHandlerService,
    private screenSizeService: ScreenSizeService
  ) {}

  ngOnInit(): void {
    this.carouselService.currentData$
      .pipe(
        filter((data: TemplatesCarouselData | null) => !!data),
        map((data: TemplatesCarouselData) => <TemplatesCarouselData>data),
        tap(() => this.carouselService.runUpdateMenu()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data: TemplatesCarouselData) => {
        const { updateOnNewCircle, forceDisplay } = data;
        this.forceDisplay = !!forceDisplay;

        this.clearDisplayTimeTimer();

        if (!this.getIsCarouselEmpty(this.templates$.value) && updateOnNewCircle) {
          this.setNewCircleUpdateData(data);
        } else {
          this.initializeNewCarouselCycle(data);
        }
      });

    this.carouselService.updateMenu$
      .pipe(
        mergeMap(() => this.carouselService.getCfsMenuList()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {});
  }

  templateReady(active: boolean, index: number, templateComponent: CfsTemplateComponent): void {
    if (
      !this.isFirstTemplateReady &&
      index === 1 &&
      !this.isSplashScreen(this.templates$.value[index]) &&
      !this.carouselService.isProgressStatus()
    ) {
      this.isFirstTemplateReady = true;

      this.carouselService.playCarousel();
    }

    if (active && templateComponent) {
      templateComponent.play();
    }
  }

  onTemplateClick(template: Template): void {
    const { configuration, defaultTemplateId, templateId, globalTemplateType } = template;
    /**
     * Temporary property for template activity logs.
     * Should be removed and used just `id` or `templateId` after backend template refactoring */
    const templateActivityId = templateId;
    const { triggers } = configuration || {};
    const action = triggers?.find(
      (x) => x.triggerType === TemplateConfigurationTriggerType.Click && x.area.areaType === TemplateConfigurationTriggerAreaType.Any
    )?.action;

    if (!action) {
      return;
    }

    switch (action.actionType) {
      case TemplateConfigurationTriggerActionType.IframeNavigation:
        this.carouselService.sendUserAction({
          actionType: CarouselUserActionType.templateAction,
          redirectUrl: action.destination.customDestination?.url,
          confirmActivity: true,
          trackActivity: true
        });

        if (defaultTemplateId) {
          const redirectData: TemplateActivityDataRedirectModel = {
            redirectUrl: action.destination.customDestination?.url!,
            isNavigateOccur: false
          };

          this.activityLogService
            .logTemplateActivity({
              templateId: templateActivityId,
              isGlobalTemplate: globalTemplateType === GlobalTemplateType.Global,
              activityType: TemplateActivityType.Redirect,
              locationId: this.settingsHandler.locationId,
              cashRegisterId: this.settingsHandler.cashRegister,
              dateFrom: new Date().toISOString(),
              count: 1,
              data: JSON.stringify(redirectData)
            })
            .subscribe((resultTemplateActivityId: string) => {
              this.localStorageService.setItem(StorageKeys.templateActivityId, resultTemplateActivityId);
            });
        }
        break;
      default:
        throw new Error(`Trigger action '${action.actionType}' is not implemented.`);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeNewCarouselCycle(data: TemplatesCarouselData): void {
    this.generateSlidesListInfo(data);
    this.carouselService.pauseCarousel();

    if (!this.isCarouselEmpty) {
      this.prepareNextSlide(this.templates$.value, false);
    }
  }

  private generateSlidesListInfo(data: TemplatesCarouselData): void {
    const { templateList = [] } = data;
    this.isCarouselEmpty = this.getIsCarouselEmpty(templateList);
    this.isFirstTemplateReady = false;
    this.nextSlideIndex = this.forceDisplay ? this.carouselService.defaultSlideIndex - 1 : this.carouselService.savedSlideIndex;

    this.templates$.next(templateList);
  }

  private prepareNextSlide(list: Template[], incrementIndex = true): void {
    if (incrementIndex) {
      this.nextSlideIndex = this.nextSlideIndex + 1 < list.length ? this.nextSlideIndex + 1 : 0;
    }

    if (!this.forceDisplay) {
      this.carouselService.saveSlideIndex(this.nextSlideIndex);
    }

    const isLastSlide = this.nextSlideIndex === list?.length - 1;
    const nextSlide: Template = list[this.nextSlideIndex];
    const displayTime = this.getDisplayTime(nextSlide);

    if (this.isSplashScreen(nextSlide)) {
      if (!this.isFirstTemplateReady) {
        this.carouselService.pauseCarousel();
      } else if (!this.carouselService.isProgressStatus()) {
        this.carouselService.playCarousel();
      } else {
        this.prepareNextSlide(list, true);
      }
    } else {
      this.prepareMediaForPlayback();
      this.nextSlideInterval$.next(displayTime);
    }

    if (isLastSlide && (this.nextCircleUpdateData || this.forceDisplay)) {
      this.displayTimeTimerId = setTimeout(() => {
        if (this.nextCircleUpdateData) {
          this.carouselService.saveSlideIndex(0);
          this.initializeNewCarouselCycle(this.nextCircleUpdateData);
          this.setNewCircleUpdateData(null);
        } else if (this.forceDisplay) {
          this.carouselService.onForcedCarouselTimeout();
          this.carouselService.updateForcedCarousel();
        }
      }, Math.max(0, displayTime - this.delayBeforeUpdate));
    }
  }

  private prepareMediaForPlayback(): void {
    if (this.templateComponents?.length) {
      this.templateComponents.forEach((templateComponent: CfsTemplateComponent, index: number) => {
        if (index === this.nextSlideIndex) {
          templateComponent.play();
        } else {
          templateComponent.stop();
        }
      });
    }
  }

  private clearDisplayTimeTimer(): void {
    if (this.displayTimeTimerId) {
      clearTimeout(this.displayTimeTimerId);
    }
  }

  private logTemplateShowActivity(): void {
    const currentTemplate: Template = this.templates$.value[this.nextSlideIndex] as Template;

    if (currentTemplate) {
      const displayTime = this.getDisplayTime(currentTemplate);
      const now = new Date();
      const dateFrom = now.toISOString();

      now.setMilliseconds(now.getMilliseconds() + displayTime).toString();

      const dateTo = new Date(now).toISOString();
      const activity: TemplateActivity = {
        templateId: currentTemplate.templateId,
        isGlobalTemplate: currentTemplate.globalTemplateType === GlobalTemplateType.Global,
        activityType: TemplateActivityType.Show,
        dateFrom,
        dateTo,
        count: 1,

        data: ""
      };

      this.templateActivityService.storeEvent(activity);
    }
  }

  private setNewCircleUpdateData(data: TemplatesCarouselData | null): void {
    this.nextCircleUpdateData = data;
  }

  private isSplashScreen(template: Template): boolean {
    return !!template?.isSplashScreen;
  }

  private getIsCarouselEmpty(templateList: Template[]): boolean {
    return !templateList?.filter((item: Template) => !this.isSplashScreen(item)).length;
  }

  private getDisplayTime(template: Template): number {
    return template?.configuration?.options?.displayTime || this.defaultInterval;
  }
}
