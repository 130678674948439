export enum TemplateActivityType {
  Show,
  Redirect
}

export type TemplateActivity = Omit<TemplateActivityPostDto, "locationId">;

export interface TemplateActivityPostDto {
  templateId: number;
  isGlobalTemplate: boolean;
  activityType: TemplateActivityType;
  locationId: number;
  cashRegisterId?: number;

  dateFrom: string;
  dateTo?: string;
  count: number;

  data: string;
}

export interface TemplateActivityDataRedirectModel {
  redirectUrl: string;
  isNavigateOccur: boolean;
}
