export enum StorageKeys {
  cashRegister = "cashRegisterId",
  locationStorageKey = "locationId",
  templateUrlListStorageKey = "template_list",
  templateActivityEventListStorageKey = "template_activity_event_list",
  templateActivityId = "template_activity_id",
  brandColorsPaletteStorageKey = "brand_colors_palette",
  connectionCode = "connection_code",
  paired = "paired",
  oldPaired = "connected",
  statusDate = "statusDate",
  incorrectPinCodeLoginAttemptsLog = "incorrect_pin_code_login_attempts_log",
  isResetInProgress = "isResetInProgress",
  testCarouselMode = "testCarouselMode"
}
